<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-page-header @back="goBack" ></el-page-header>
      <el-breadcrumb-item>课程资料</el-breadcrumb-item>
      <el-breadcrumb-item>{{prames.title}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{prames.type}}</el-breadcrumb-item>
    </el-breadcrumb>
    <p class="bold">{{prames.LessonNo}}</p>
    <ul>
      <li class="bgpurple flexBtw list"
          v-for="(pitem,pindex) in files"
          :key="pindex">
        <p>{{pitem.FileName}}</p>
        <p class="flex">
          <img src="../../assets/img/download_icon.png"
               alt="">
          <span @click="download(pitem.Url,pitem.FileName)">下载</span>
          <!-- <a :href="pitem.Url" :download="pitem.FileName">下载</a> -->
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import { get_materials_details } from '@/api/classesinfo'
export default {
  name: 'coursemater_material',
  data () {
    return {
      isweixin: false,
      prames:{},
      files:{}
    };
  },
  created () {
    var prames = sessionStorage.getItem('stu_coursemater_query')? JSON.parse(sessionStorage.getItem('stu_coursemater_query')):{}
    this.prames=prames;
    this.getInit()
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    async getInit () {
      let prames = {
        userid: this.prames.userid,
        SolutionID:this.prames.SolutionID,
        FileType:this.prames.FileType
      }
      let res = await get_materials_details(prames)
      if (res.flag == '1') {
        this.files = res.datainfo.File;
      }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }
    },
    download(url, fileName) {
        const url2 = url.replace(/\\/g, '/');
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url2, true);
        xhr.responseType = 'blob';
        //xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
        // 为了避免大文件影响用户体验，建议加loading
        xhr.onload = () => {
            if (xhr.status === 200) {
                this.saveAs(xhr.response, fileName);
            }
        };
        xhr.send();
    },
    saveAs(data, name) {
      const urlObject = window.URL || window.webkitURL || window;
      const export_blob = new Blob([data]);
      const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
      save_link.href = urlObject.createObjectURL(export_blob);
      save_link.download = name;
      save_link.click();
    }
  },

}
</script>
<style scoped lang="scss">
.list {
  padding: 20px 25px;
  margin-top: 15px;
  border-radius: 5px;
  border: 1px solid #e9e9ec;
  font-size: 18px;
  img {
    margin-right: 5px;
    cursor: pointer;
  }
  span {
    color: #6f72ff;
    cursor: pointer;
  }
}
</style>